import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordName } from 'vue-router';

import { useUserStore } from '@/stores/userStore';

const checkParents = (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean => {
  if (to.meta.entryPoint) return true;
  return !!(
    (to.meta.parents && (to.meta.parents as RouteRecordName[]).includes(from?.name || '')) ||
    (from.meta.parents && (from.meta.parents as RouteRecordName[]).includes(to?.name || ''))
  );
};

const funnelCheckParents = async (to: RouteLocationNormalized, from: RouteLocationNormalized): Promise<boolean> => {
  if (checkParents(to, from)) return true;
  else {
    // hack to go back from shopify)
    const infoBuilder = JSON.parse(localStorage.getItem('builder') || '{}');
    if (infoBuilder?.checkout_redirect && to.path === '/step-3' && from.path === '/') return true;
    else router.push({ name: 'step-1', query: to.query });
    return false;
  }
};

const fbcCheckParents = (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean => {
  if (checkParents(to, from)) return true;
  else {
    // hack to go back from shopify
    const infoBuilder = JSON.parse(localStorage.getItem('fbc-builder') || '{}');
    if (infoBuilder?.checkout_redirect && to.path === '/subscribe' && from.path === '/') return true;
    else router.push({ name: 'fbc-step-2', query: to.query });
    return false;
  }
};

const registerCheckParents = (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean => {
  if (checkParents(to, from)) return true;
  else {
    // hack to go back from shopify
    const infoBuilder = JSON.parse(localStorage.getItem('register-builder') || '{}');
    if (infoBuilder?.checkout_redirect && to.path === '/register-funnel/step-2' && from.path === '/') return true;
    else router.push({ name: 'register-funnel', query: to.query });
    return false;
  }
};

const discountFunnelCheckParents = (to: RouteLocationNormalized, from: RouteLocationNormalized): boolean => {
  if (checkParents(to, from)) return true;
  else {
    // hack to go back from shopify
    const infoBuilder = JSON.parse(localStorage.getItem('discount-funnel-builder') || '{}');
    if (infoBuilder?.checkout_redirect && to.path === '/discount-pinter/step-2' && from.path === '/') return true;
    else router.push({ name: 'discount-funnel', query: to.query });
    return false;
  }
};

export const authRouteGuard = async (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  const userStore = useUserStore();
  await userStore.getUserDetails();
  const logged = !!userStore.attributes?.id;
  logged ? next() : (window.location.href = `${import.meta.env.VITE_SHOPIFY_URL}/account/login`);
};

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && to.hash !== '#modal') {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/components/funnel/FunnelRoot.vue'),
      beforeEnter: async (to, from, next) => {
        const userStore = useUserStore();
        userStore.getUserDetails();
        next();
      },
      children: [
        {
          path: '',
          component: () => import('@/views/funnel/Step1View.vue'),
          name: 'step-1',
          meta: {
            parents: ['/', '', undefined, 'step-2', 'step-3'],
            entryPoint: true,
          },
          beforeEnter: [funnelCheckParents],
        },
        {
          path: 'step-2',
          component: () => import('@/views/funnel/Step2View.vue'),
          name: 'step-2',
          meta: { parents: ['step-1', 'step-3'] },
          beforeEnter: [funnelCheckParents],
        },
        {
          path: 'step-3',
          component: () => import('@/views/funnel/Step3View.vue'),
          name: 'step-3',
          meta: { parents: [undefined, 'step-2'] },
          beforeEnter: [funnelCheckParents],
        },
        {
          path: 'user',
          component: () => import('@/views/funnel/UserView.vue'),
          name: 'pinter-user',
          meta: { parents: ['step-3'], reload: true },
          beforeEnter: [funnelCheckParents],
        },
      ],
    },
    {
      path: '/subscribe',
      component: () => import('@/components/fbc-funnel/FbcRoot.vue'),
      beforeEnter: async (to, from, next) => {
        const userStore = useUserStore();
        userStore.getUserDetails();
        next();
      },
      children: [
        {
          path: '',
          component: () => import('@/views/fbc-funnel/FbcStep2View.vue'),
          name: 'fbc-step-2',
          meta: {
            parents: ['/', '', undefined],
            entryPoint: true,
          },
          beforeEnter: [fbcCheckParents],
        },
        {
          path: 'user',
          component: () => import('@/views/fbc-funnel/UserView.vue'),
          name: 'fbc-user',
          meta: { parents: ['fbc-step-2'], reload: true },
          beforeEnter: [fbcCheckParents],
        },
      ],
    },
    {
      path: '/free-pinter',
      component: () => import('@/components/register-funnel/RegisterFunnelRoot.vue'),
      beforeEnter: async (to, from, next) => {
        const userStore = useUserStore();
        userStore.getUserDetails();
        next();
      },
      children: [
        {
          path: '',
          component: () => import('@/views/register-funnel/RegisterFunnelView.vue'),
          name: 'register-funnel',
          meta: {
            parents: ['/', '', undefined],
            entryPoint: true,
          },
          beforeEnter: [registerCheckParents],
        },
        {
          path: 'step-1',
          component: () => import('@/views/register-funnel/RegisterFunnelStep1View.vue'),
          name: 'register-funnel-step-1',
          meta: {
            parents: ['register-funnel', 'register-funnel-step-2'],
          },
          beforeEnter: [registerCheckParents],
        },
        {
          path: 'step-2',
          component: () => import('@/views/register-funnel/RegisterFunnelStep2View.vue'),
          name: 'register-funnel-step-2',
          meta: { parents: ['register-funnel-step-1'] },
          beforeEnter: [registerCheckParents],
        },
        {
          path: 'user',
          component: () => import('@/views/register-funnel/RegisterUserView.vue'),
          name: 'register-funnel-step-3',
          meta: { parents: ['register-funnel-step-2'] },
          beforeEnter: [registerCheckParents],
        },
      ],
    },
    {
      path: '/discount-pinter',
      component: () => import('@/components/discount-funnel/DiscountFunnelRoot.vue'),
      beforeEnter: async (to, from, next) => {
        const userStore = useUserStore();
        userStore.getUserDetails();
        next();
      },
      children: [
        {
          path: '',
          component: () => import('@/views/discount-funnel/DiscountFunnelView.vue'),
          name: 'discount-funnel',
          meta: {
            parents: ['/', '', undefined],
            entryPoint: true,
          },
          beforeEnter: [discountFunnelCheckParents],
        },
        {
          path: 'step-1',
          component: () => import('@/views/discount-funnel/DiscountFunnelStep1View.vue'),
          name: 'discount-funnel-step-1',
          meta: {
            parents: ['discount-funnel', 'discount-funnel-step-2'],
          },
          beforeEnter: [discountFunnelCheckParents],
        },
        {
          path: 'step-2',
          component: () => import('@/views/discount-funnel/DiscountFunnelStep2View.vue'),
          name: 'discount-funnel-step-2',
          meta: { parents: ['discount-funnel-step-1'] },
          beforeEnter: [discountFunnelCheckParents],
        },
        {
          path: 'user',
          component: () => import('@/views/discount-funnel/DiscountUserView.vue'),
          name: 'discount-funnel-step-3',
          meta: { parents: ['discount-funnel-step-2'] },
          beforeEnter: [discountFunnelCheckParents],
        },
      ],
    },
    {
      path: '/account',
      component: () => import('@/components/account/AccountRoot.vue'),
      beforeEnter: async (to, from, next) => {
        await authRouteGuard(to, next);
      },
      children: [
        {
          path: '',
          component: () => import('@/components/account/AccountOverviewRoot.vue'),
          props: true,
          children: [
            {
              path: '',
              component: () => import('@/components/account/AccountOverview.vue'),
              name: 'account-overview',
            },
            {
              path: '/account/overview/edit-order/:id',
              component: () => import('@/components/account/AccountEditOrder.vue'),
              name: 'account-overview-edit',
              props: true,
            },
          ],
        },
        {
          path: '/account/details',
          component: () => import('@/components/account/AccountDetailsRoot.vue'),
          children: [
            {
              path: '',
              component: () => import('@/components/account/AccountDetails.vue'),
              name: 'account-details',
            },
            {
              path: '/account/details/adresses',
              component: () => import('@/components/account/addresses/AddressesRoot.vue'),
              children: [
                {
                  path: '',
                  component: () => import('@/components/account/addresses/Addresses.vue'),
                  name: 'account-addresses',
                },
                {
                  path: '/account/details/adresses/edit/:id',
                  component: () => import('@/components/account/addresses/AddressesEdit.vue'),
                  name: 'account-addresses-edit',
                  props: true,
                },
                {
                  path: '/account/details/adresses/new',
                  component: () => import('@/components/account/addresses/AddressesNew.vue'),
                  name: 'account-addresses-new',
                },
              ],
            },
            {
              path: '/account/details/payment',
              component: () => import('@/components/account/paymentMethod/PaymentMethod.vue'),
              name: 'account-payment-method',
            },
            {
              path: '/account/details/contact',
              component: () => import('@/components/account/contactDetails/ContactDetailsRoot.vue'),
              children: [
                {
                  path: '',
                  component: () => import('@/components/account/contactDetails/ContactDetails.vue'),
                  name: 'account-contact-details',
                },
                {
                  path: '/account/details/contact/phone',
                  component: () => import('@/components/account/contactDetails/ContactDetailsPhone.vue'),
                  name: 'account-contact-details-phone',
                },
              ],
            },
          ],
        },
        // {
        // 	path: '/account/referrals',
        // 	component: () => import('@/components/account/AccountReferrals.vue'),
        // 	name: 'account-referrals',
        // },
        {
          path: '/account/subscription',
          component: () => import('@/components/account/AccountSubscriptionRoot.vue'),
          children: [
            {
              path: '',
              component: () => import('@/components/account/AccountSubscription.vue'),
              name: 'account-subscription',
            },
            {
              path: '/account/subscription/edit-order/:id',
              component: () => import('@/components/account/AccountEditOrder.vue'),
              name: 'account-subscription-edit',
              props: true,
              beforeEnter: (to, from, next) => {
                next();
              },
            },
          ],
        },
        {
          path: '/account/history',
          component: () => import('@/components/account/AccountHistory.vue'),
          name: 'account-history',
        },
      ],
    },
    {
      path: '/subscribe/:pathMatch(.*)*',
      name: 'fbc-not-found',
      redirect: { name: 'fbc-step-2' },
    },
    {
      path: '/register-funnel/:pathMatch(.*)*',
      name: 'fbc-not-found',
      redirect: { name: 'register-funnel' },
    },
    {
      path: '/account/:pathMatch(.*)*',
      name: 'account-not-found',
      redirect: { name: 'account-overview' },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      redirect: { name: 'step-1' },
    },
  ],
});

router.beforeEach(function (to, from, next) {
  if (Object.keys(from.query).length > 0 && Object.keys(to.query).length === 0) {
    next({ name: to.name || '', query: from.query, params: to.params });
  } else next();
});

export default router;
